.Stepper {
  padding-top: 20px;
  height: 90%;
  margin-right: 0px;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  cursor: pointer;

}

.MuiStepLabel-root {
  display: flex;
  flex-direction: row;
  /* Default horizontal layout */
  align-items: center;
}

/* When the Stepper is horizontal */
.MuiStepLabel-horizontal {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
}

.MuiStepLabel-horizontal .MuiStepLabel-label {
  margin-top: 5px;
  font-size: 16px;
  color: #16697a;
}

.MuiStepLabel-horizontal .MuiStepLabel-iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}



.Step.completed .MuiStepLabel-label {
  color: #16697a;
}

.Step.active .MuiStepLabel-label {
  color: #16697a;
  font-weight: bold;
}

.Step.active .MuiStepIcon-root {
  color: #16697a;
}

.Step.completed .MuiStepIcon-root {
  color: #16697a;
}

.Step .MuiStepIcon-root {
  color: hsla(190, 69%, 28%, 0.5);
}



.MuiStepConnector-line {
  border-color: #16697a;
  height: 7vh;
}