.instructions-box {
    background-color: transparent;
    border: 0px solid #ddd;
    border-radius: 8px;
    padding: 0;
    margin: 16px;
    color: #fff;
    text-align: left;
    font-size: 0.95rem;

}

.instructions-box h2 {
    font-size: 1.5rem;
    color: #fff;
    text-align: left;

}

.instructions-box p {
    font-size: 0.95rem;
    color: #fff;
    text-align: left;
    margin-top: 0px;
}

.instruction-item {
    margin-bottom: 16px;
}

.heading-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 8px 8px 8px 0px;
    background-color: transparent;
    border-radius: 4px;
}

.heading-toggle h4 {
    color: #ffffff;
    margin: 0;
}

.toggle-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #ffffff;
}

.toggle-button:hover {
    color: #FFA62B;
}