.footer {
  background-color: #175c6b;
  color: white;
  padding: 20px 0;
  text-align: center;
}

.footer-container {
  width: 80%;
  margin: 0 auto;
}

.footer-logo {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.tech {
  color: orange;
}

.scaler {
  color: white;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-bottom: 20px;
}

.footer-column h4 {
  margin-bottom: 10px;
}

.footer-column a {
  display: block;
  color: white;
  text-decoration: none;
  margin: 5px 0;
  font-size: 14px;
}

.footer-column a:hover {
  text-decoration: underline;
}

.footer-bottom {
  font-size: 14px;
  margin-top: 10px;
}